export const months = [
	{
		name: "Janeiro",
		value: "Janeiro",
		key: 1,
	},
	{
		name: "Fevereiro",
		value: "Fevereiro",
		key: 2,
	},
	{
		name: "Março",
		value: "Março",
		key: 3,
	},
	{
		name: "Abril",
		value: "Abril",
		key: 4,
	},
	{
		name: "Maio",
		value: "Maio",
		key: 5,
	},
	{
		name: "Junho",
		value: "Junho",
		key: 6,
	},
	{
		name: "Julho",
		value: "Julho",
		key: 7,
	},
	{
		name: "Agosto",
		value: "Agosto",
		key: 8,
	},
	{
		name: "Setembro",
		value: "Setembro",
		key: 9,
	},
	{
		name: "Outubro",
		value: "Outubro",
		key: 10,
	},
	{
		name: "Novembro",
		value: "Novembro",
		key: 11,
	},
	{
		name: "Dezembro",
		value: "Dezembro",
		key: 12,
	},
];

export const maxAgeAllowed = 18;

export function monthsHelper(month) {
	switch (month) {
		case 1:
			return "Janeiro";
			break;
		case 2:
			return "Fevereiro";
			break;
		case 3:
			return "Março";
			break;
		case 4:
			return "Abril";
			break;
		case 5:
			return "Maio";
			break;
		case 6:
			return "Junho";
			break;
		case 7:
			return "Julho";
			break;
		case 8:
			return "Agosto";
			break;
		case 9:
			return "Setembro";
			break;
		case 10:
			return "Outubro";
			break;
		case 11:
			return "Novembro";
			break;
		case 12:
			return "Dezembro";
			break;
	}
}

export function monthsNameHelper(month) {
	switch (month) {
		case "Janeiro":
			return 1;
			break;
		case "Fevereiro":
			return 2;
			break;
		case "Março":
			return 3;
			break;
		case "Abril":
			return 4;
			break;
		case "Maio":
			return 5;
			break;
		case "Junho":
			return 6;
			break;
		case "Julho":
			return 7;
			break;
		case "Agosto":
			return 8;
			break;
		case "Setembro":
			return 9;
			break;
		case "Outubro":
			return 10;
			break;
		case "Novembro":
			return 11;
			break;
		case "Dezembro":
			return 12;
			break;
		default:
			return month;
			break;
	}
}
